import React from 'react';
import { Typography } from '@mui/material';
const blueishBlack = '#151A3A';

const Footer = () => {
  return (
    <div
      style={{
        background: blueishBlack,
        height: '32px',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <Typography style={{ color: '#5A5A89' }}>
        Copyright &copy; 2021 &mdash; WiseSoft, LLC
      </Typography>
    </div>
  );
};

export default Footer;
