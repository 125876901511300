import aaron from "./aaron.svg";
import alice from "./alice.svg";
import bruce from "./bruce.svg";
import christoph from "./christoph.svg";
import devin from "./devin.svg";
import garrick from "./garrick.svg";
import james from "./james.svg";
import peter from "./peter.svg";
import rene from "./rene.svg";
import sapir from "./sapir.svg";
import thomas from "./thomas.svg";
import vitally from "./vitally.svg";

const images = {
    aaron,
    alice,
    bruce,
    christoph,
    devin,
    garrick,
    james,
    peter,
    rene,
    sapir,
    thomas,
    vitally
};

export default images;

