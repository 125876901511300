import React, { useState, useEffect } from 'react';
import {
  useTransition,
  animated,
  config,
  useChain,
  useSpringRef,
  useSpring
} from 'react-spring';
import {
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import orb1 from '../assets/images/orb1.svg';
import orb2 from '../assets/images/orb2.svg';
import orb3 from '../assets/images/orb3.svg';
import AnimatedLine from './AnimatedLine';
const roadmapBackground =
  'linear-gradient(180deg, #2724CA 3.15%, #282998 14.43%, #2A3058 26.22%';

const useStyles = makeStyles((theme) => ({
  orb: {
    zIndex: '10',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    marginBottom: '5rem',
    borderRadius: '1000px',
    // background: 'radial-gradient(#29ABB5 0%, #2B3159 100%)',
    // background: 'linear-gradient(180deg, #29ABB5 0%, #2B3159 100%)',
    background: 'radial-gradient(64.23% 64.23% at 50% 50%, #D7FAFD 4.17%, #29ABB5 98.96%)',
    // background: "linear-gradient(180deg, #29ABB5 0%, rgba(41, 171, 181, 0) 100%)",
    boxShadow:
    '4px 4px 32px -1px #6CEDC030, inset 0px 4px 13px rgba(25, 50, 78, 0.82)',
    // 'inset 0px 4px 13px rgba(25, 50, 78, 0.82)',
    //   '4px 4px 32px -1px #6CEDC0, inset 0px 4px 13px rgba(25, 50, 78, 0.82)',
    width: '175px',
    height: '175px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '120px',
      height: '120px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '90px',
      height: '90px'
    }
  },
  orbIcon: {
    width: '105px',
    height: '105px',
    [theme.breakpoints.down('md')]: {
      width: '75px',
      height: '75px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '55px',
      height: '55px'
    }
  },

}));

const RoadmapSection = ({ roadmapRef }) => {
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const col1Ref = useSpringRef();
  const col2Ref = useSpringRef();
  const col3Ref = useSpringRef();

  const { ref, inView } = useInView({
    threshold: 0.6,
    triggerOnce: true
  });
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    if (inView && !scrolled) setScrolled(true);
  }, [inView, scrolled]);

  const column1 = [
    <Typography
      style={{
        color: '#FB00A1',
        fontSize: mediumScreen ? '18px' : '25px',
        lineHeight: '32px',
        fontWeight: 'bold'
      }}>
      Step 1
    </Typography>,
    <Typography
      style={{
        color: '#FB00A1',
        fontSize: mediumScreen ? '16px' : '30px',
        lineHeight: '28px',
        textAlign: 'center'
      }}>
      LiquidNFTs
    </Typography>,
    <Typography
      style={{
        color: 'white',
        fontSize: mediumScreen ? '15px' : '18px',
        lineHeight: '28px',
        textAlign: 'center',
        height: '6.5rem',
        padding: '0 1rem'
      }}>
      Flagship platform that merges NFTs and DeFi
    </Typography>,

    <div className={classes.orb}>
      <img alt='wise logo' src={orb1} className={classes.orbIcon} />
    </div>
  ];

  const column2 = [
    <Typography
      style={{
        color: '#FB00A1',
        fontSize: mediumScreen ? '18px' : '25px',
        lineHeight: '32px',
        fontWeight: 'bold'
      }}>
      Step 2
    </Typography>,
    <Typography
      style={{
        color: '#FB00A1',
        fontSize: mediumScreen ? '16px' : '30px',
        lineHeight: '28px',
        textAlign: 'center'
      }}>
      NFT Marketplace
    </Typography>,
    <Typography
      style={{
        color: 'white',
        fontSize: mediumScreen ? '15px' : '18px',
        lineHeight: '28px',
        textAlign: 'center',
        height: '6.5rem',
        padding: '0 1rem'
      }}>
      Data-driven service that prioritizes artists
    </Typography>,
    <div className={classes.orb}>
      <img alt='nft marketplace' src={orb2} className={classes.orbIcon} />
    </div>
  ];
  const column3 = [
    <Typography
      style={{
        color: '#FB00A1',
        fontSize: mediumScreen ? '18px' : '25px',
        lineHeight: '32px',
        fontWeight: 'bold'
      }}>
      Step 3
    </Typography>,
    <Typography
      style={{
        color: '#FB00A1',
        fontSize: mediumScreen ? '16px' : '30px',
        lineHeight: '28px',
        textAlign: 'center'
      }}>
      Launchpad
    </Typography>,
    <Typography
      style={{
        color: 'white',
        fontSize: mediumScreen ? '15px' : '18px',
        lineHeight: '28px',
        textAlign: 'center',
        height: '6.5rem',
        padding: '0 1rem'
      }}>
      A service for new community-centric NFT launches
    </Typography>,
    <div className={classes.orb}>
      <img alt='rocket ship' src={orb3} className={classes.orbIcon} />
    </div>
  ];

  const titleAnimation = useSpring({
    from: { opacity: '0', transform: 'translateY(-40px)' },
    to: { opacity: '1', transform: 'translateY(0)' },
    config: { mass: 1, tension: 170, friction: 5 },
  });

  const column1Animation = useTransition(column1, {
    from: { opacity: '0', transform: 'translateY(-20px)' },
    enter: { opacity: '1', transform: 'translateY(0px)' },
    trail: 260,
    config: config.default,
    ref: col1Ref
  });
  const column2Animation = useTransition(column2, {
    from: { opacity: '0', transform: 'translateY(-20px)' },
    enter: { opacity: '1', transform: 'translateY(0px)' },
    trail: 260,
    config: config.default,
    ref: col2Ref
  });
  const column3Animation = useTransition(column3, {
    from: { opacity: '0', transform: 'translateY(-20px)' },
    enter: { opacity: '1', transform: 'translateY(0px)' },
    trail: 260,
    config: config.default,
    ref: col3Ref
  });

  useChain([col1Ref, col2Ref, col3Ref]);

  return (
    <div
      id='roadmap'
      ref={ref}
      style={{
        background: roadmapBackground,
        height: '732px',
        position: 'relative'
      }}>
      <div ref={roadmapRef} />
      {scrolled && (
        <>
          <animated.div style={titleAnimation}>
            <Typography
              style={{
                paddingTop: '1rem',
                fontSize: '30px',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'white',
                marginBottom: '8rem'
              }}>
              ROADMAP
            </Typography>
          </animated.div>
          <AnimatedLine />
          <Grid container>
            <Grid item xs={4}>
              <Grid container direction='column'>
                {column1Animation((props, item) => (
                  <Grid
                    item
                    xs={3}
                    align='center'
                    justify='center'
                    style={{ marginBottom: '1.25rem', padding: '0.5rem' }}>
                    <animated.div style={props}>{item}</animated.div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction='column'>
                {column2Animation((props, item) => (
                  <Grid
                    item
                    xs={3}
                    align='center'
                    justify='center'
                    style={{ marginBottom: '1.25rem', padding: '0.5rem' }}>
                    <animated.div style={props}>{item}</animated.div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container direction='column'>
                {column3Animation((props, item) => (
                  <Grid
                    item
                    xs={3}
                    align='center'
                    justify='center'
                    style={{ marginBottom: '1.25rem', padding: '0.5rem' }}>
                    <animated.div style={props}>{item}</animated.div>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default RoadmapSection;
