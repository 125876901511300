import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  tealLine: {
    background: '#29ABB5',
    height: '5px',
    transform: 'translateY(370px)',
    zIndex: '1',
    [theme.breakpoints.down("md")]: {
        transform: 'translateY(342px)',
    },
    [theme.breakpoints.down("sm")]: {
        transform: 'translateY(328px)',
    }
  },
}));

const AnimatedLine = () => {
  const classes = useStyles();
  const [showLine, setShowLine] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowLine(true), 1500);
  }, []);

  const lineAnimation = useSpring({

    opacity: showLine ? '1' : '0',
    boxShadow: showLine ? '4px 4px 32px #6CEDC0' : '0px 0px 32px #6CEDC000',
    width: showLine ? '100%' : '0%',
    config: {
        // mass: 3,
        // tension: 100,
        // friction: 250,
        duration: 3500
    }
  });

  return (
    <div>
      <animated.div style={lineAnimation} className={classes.tealLine} />
    </div>
  );
};

export default AnimatedLine;
