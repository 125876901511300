import React, { useState, useEffect, useRef } from 'react';

import NavBar from '../components/NavBar';
import OrbsSection from '../components/OrbsSection';
import IntroductionSection from '../components/IntroductionSection';
import RoadmapSection from '../components/RoadmapSection';
import ScreenshotSection from '../components/ScreenshotSection';
import FeaturesSection from '../components/FeaturesSection';
import TeamSection from '../components/TeamSection';
import Footer from '../components/Footer';

const LandingPage = () => {
  const [currentPage, setCurrentPage] = useState();
  const overViewRef = useRef();
  const featuresRef = useRef();
  const roadmapRef = useRef();
  const teamRef = useRef();

  useEffect(() => {
    const refs = {
      overview: overViewRef,
      features: featuresRef,
      roadmap: roadmapRef,
      team: teamRef
    };
    if (currentPage && refs[currentPage] && refs[currentPage].current) {
      refs[currentPage].current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentPage]);

  return (
    <div className='App' style={{ overflowX: 'hidden' }}>
      <NavBar setCurrentPage={setCurrentPage} currentPage={currentPage} />
      <OrbsSection />
      <IntroductionSection overViewRef={overViewRef} />
      <FeaturesSection featuresRef={featuresRef} />
      <ScreenshotSection />
      <RoadmapSection roadmapRef={roadmapRef} />
      <TeamSection teamRef={teamRef} />
      <Footer />
    </div>
  );
};

export default LandingPage;
