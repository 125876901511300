import { createTheme } from '@mui/material/styles';
import avenir from '../assets/fonts/AvenirLTProBlack.woff';

const sharedTheme = createTheme({
  palette: {
    brand: {
      gradBlue: '#2724CA',
      wiseTeal: '#29ABB5',
      magenta: '#FF00A4',
    },
  },
  typography: {
    fontFamily: 'Avenir LT Pro, Arial',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Avenir LT Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Avenir LT Pro'), url(${avenir}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const dark = createTheme({
  ...sharedTheme,
});

const light = createTheme({
  ...sharedTheme,
});

const themes = {
  dark,
  light,
};

export default themes;
