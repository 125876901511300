import React, { useState } from 'react';
import logo from '../assets/images/owl.svg';
import menu from '../assets/images/icons/menu.svg';
import { Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import close from '../assets/images/icons/close.svg';

const useStyles = makeStyles(() => ({
  link: {
    color: '#6A6A9F !important',
    '&:hover': {
      color: 'rgb(251, 0, 161) !important'
    }
  },
  linkMobile: {
    color: 'white !important',
    '&:hover': {
      color: 'rgb(251, 0, 161) !important'
    }
  },
  menu: {
    display: 'block',
    zIndex: '9003',
    '&:hover': {
      opacity: 0.8
    }
  }
}));

const NavBar = ({ setCurrentPage, currentPage }) => {
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const handleClick = ({ target: { id } }) => {
    if (currentPage !== id) setCurrentPage(id);
    else {
      setCurrentPage(null);
      setTimeout(() => setCurrentPage(id), 100);
    }
  };
  if (mediumScreen) return <MobileNav handleClick={handleClick} />;
  return (
    <div
      style={{
        height: '64px',
        background: '#151A3A',
        boxShadow:
          '2px 2px 11px 2px rgba(19, 27, 104, 0.3), 1px 1px 20px 1px rgba(255, 0, 164, 0.5)',
        zIndex: '9',
        width: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '1rem',
        justifyContent: 'space-between'
      }}>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        onClick={handleClick}
        id='top'>
        <img src={logo} alt='owl logo' style={{ marginRight: '1.5rem' }} />
        <Typography style={{ color: 'white' }}>LiquidNFTs</Typography>
      </div>
      <div style={{ display: 'flex' }}>
        <Typography
          id='overview'
          onClick={handleClick}
          className={classes.link}
          style={{
            marginRight: '2.5rem',
            cursor: 'pointer'
          }}>
          Overview
        </Typography>

        <Typography
          id='features'
          onClick={handleClick}
          className={classes.link}
          style={{
            marginRight: '2.5rem',
            cursor: 'pointer'
          }}>
          Features
        </Typography>

        <Typography
          id='roadmap'
          onClick={handleClick}
          className={classes.link}
          style={{
            marginRight: '2.5rem',
            cursor: 'pointer'
          }}>
          Roadmap
        </Typography>

        <Typography
          id='team'
          onClick={handleClick}
          className={classes.link}
          style={{
            marginRight: '2.5rem',
            cursor: 'pointer'
          }}>
          Team
        </Typography>
      </div>
    </div>
  );
};

const MobileNav = ({ handleClick }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen((open) => !open);
  const closeAndHandleClick = (e) => {
    toggleOpen();
    handleClick(e);
  }
  const classes = useStyles();

  return (
    <>
      <div
        onClick={toggleOpen}
        style={{
          position: 'fixed',
          right: '1rem',
          top: '0.5rem',
          cursor: 'pointer',
          zIndex: '9001',
          display: 'flex'
        }}>
        <img
          className={classes.menu}
          src={menu}
          alt='menu toggle'
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div
        style={{
          borderRadius: '15px 0px 0px 15px',
          height: 'auto',
          width: '220px',
          background: '#131B68',
          color: 'white',
          position: 'fixed',
          right: '0',
          transform: open ? 'translateX(0%)' : 'translateX(100%)',
          transition: '0.375s all ease',
          zIndex: '9001',
          borderLeft: '1px solid #A0AEC0',
          borderBottom: '1px solid #A0AEC0'
        }}>
        <img
          className={classes.menu}
          src={close}
          onClick={toggleOpen}
          alt='close'
          style={{
            cursor: 'pointer',
            float: 'right',
            marginRight: '1.5rem',
            marginTop: '1rem'
          }}
        />
        <div
          style={{
            dispay: 'flex',
            flexDirection: 'column',
            textAlign: 'right',
            marginTop: '3.5rem',
            marginRight: '1.5rem'
          }}>
          <Typography
            id='overview'
            onClick={closeAndHandleClick}
            className={classes.linkMobile}
            style={{
              marginRight: '2.5rem',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: '700',
              marginBottom: '2rem'
            }}>
            Overview
          </Typography>

          <Typography
            id='features'
            onClick={closeAndHandleClick}
            className={classes.linkMobile}
            style={{
              marginRight: '2.5rem',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: '700',
              marginBottom: '2rem'
            }}>
            Features
          </Typography>

          <Typography
            id='roadmap'
            onClick={closeAndHandleClick}
            className={classes.linkMobile}
            style={{
              marginRight: '2.5rem',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: '700',
              marginBottom: '2rem'
            }}>
            Roadmap
          </Typography>

          <Typography
            id='team'
            onClick={closeAndHandleClick}
            className={classes.linkMobile}
            style={{
              marginRight: '2.5rem',
              cursor: 'pointer',
              fontSize: '20px',
              fontWeight: '700',
              marginBottom: '2rem'
            }}>
            Team
          </Typography>
        </div>
      </div>
    </>
  );
};

export default NavBar;
