import React, { useState, useEffect } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated, config } from 'react-spring';
import meterSection from '../assets/images/meterSection.svg';
import mobileLeftCircle from '../assets/images/mobileLeftCircle.svg';
import mobileRightCircle from '../assets/images/mobileRightCircle.svg';
import mobileLines from '../assets/images/mobileLines.svg';
import rightPaddle from '../assets/images/rightPaddle.svg';
import leftPaddle from '../assets/images/leftPaddle.svg';
const blueishBlack = '#151A3A';

const useStyles = makeStyles(() => ({
  leftPaddleDesktop: {
    width: '8%',
    position: 'absolute',
    left: '.8rem',
    zIndex: '11',
    transformOrigin: '20% 85%'
  },
  leftPaddleMobile: {
    left: '.8rem',
    position: 'absolute',
    width: '75px',
    zIndex: '11',
    transformOrigin: '20% 85%'
  },
  rightPaddleDesktop: {
    position: 'absolute',
    right: '.8rem',
    width: '8%',
    zIndex: '11',
    transformOrigin: '80% 85%'
  },
  rightPaddleMobile: {
    right: '.8rem',
    position: 'absolute',
    width: '75px',
    zIndex: '11',
    transformOrigin: '80% 85%'
  }
}));

const FeaturesSection = ({ featuresRef }) => {
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [scrolled, setScrolled] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && !scrolled) setScrolled(true);
  }, [inView, scrolled]);
  console.log({ config });
  const paddleAnimationConfig = config.molasses;

  const mobileLeftPaddleAnimation = useSpring({
    transform: scrolled ? 'rotate(114deg)' : 'rotate(-3deg)',
    top: '400px',
    config: paddleAnimationConfig
  });
  const desktopLeftPaddleAnimation = useSpring({
    transform: scrolled ? 'rotate(117deg)' : 'rotate(-3deg)',
    top: '30%',
    config: paddleAnimationConfig
  });

  const mobileRightPaddleAnimation = useSpring({
    transform: scrolled ? 'rotate(-117deg)' : 'rotate(-2deg)',
    top: '1035px',
    config: paddleAnimationConfig
  });
  const desktopRightPaddleAnimation = useSpring({
    transform: scrolled ? 'rotate(-117deg)' : 'rotate(-2deg)',
    top: '30%',
    config: paddleAnimationConfig
  });

  return (
    <div
      id='features'
      ref={featuresRef}
      style={{
        minHeight: '100vh',
        background: blueishBlack,
        color: 'white',
        width: '100vw',
        paddingTop: '5rem',
        position: 'relative'
      }}>
      <div ref={ref} style={{ transform: 'translateY(600px)' }} />

      {!mediumScreen && (
        <animated.img
          style={desktopLeftPaddleAnimation}
          src={leftPaddle}
          className={classes.leftPaddleDesktop}
        />
      )}
      {mediumScreen && (
        <animated.img
          style={mobileLeftPaddleAnimation}
          src={leftPaddle}
          className={classes.leftPaddleMobile}
        />
      )}

      {!mediumScreen && (
        <animated.img
          style={desktopRightPaddleAnimation}
          src={rightPaddle}
          className={classes.rightPaddleDesktop}
        />
      )}
      {mediumScreen && (
        <animated.img
          style={mobileRightPaddleAnimation}
          src={rightPaddle}
          className={classes.rightPaddleMobile}
        />
      )}

      {!mediumScreen && (
        <img
          src={meterSection}
          alt='features'
          style={{ width: '100vw', transform: 'translateY(-10%)' }}
        />
      )}
      {mediumScreen && (
        <div
          style={{
            position: 'relative',
            paddingTop: '9rem',
            minHeight: '800px'
          }}>
          <Typography
            style={{
              fontSize: '20px',
              fontWeight: '700',
              textAlign: 'center',
              padding: '2rem 0',
              zIndex: '9000',
              position: 'absolute',
              width: '100%',
              top: '0'
            }}>
            FEATURES
          </Typography>
          <img
            src={mobileLeftCircle}
            alt='left meter'
            style={{
              marginTop: '7rem',
              position: 'absolute',
              zIndex: '10'
            }}
          />
          <img
            src={mobileLines}
            alt='decoration'
            style={{
              position: 'absolute',
              top: '-40px',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: '0'
            }}
          />
          <img
            src={mobileRightCircle}
            alt='right meter'
            style={{
              right: '0',
              marginTop: '46rem',
              position: 'absolute'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default FeaturesSection;
