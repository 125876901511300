import React from "react";
import LandingPage from "./pages/LandingPage";
import { ThemeProvider } from '@mui/material/styles';
import "./App.scss";
import themes from "./themes/Themes";

function App() {

  return (
    <ThemeProvider theme={themes.dark}>
      <LandingPage />
    </ThemeProvider>
  )
  
}

export default App;
