import React from 'react';
import orbs from '../assets/images/orbs.svg';
const darkPurple = '#151A3A';

const OrbsSection = () => {
  return (
    <div
      id='top'
      style={{
        height: '100vh',
        background: darkPurple,
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '7rem',
        paddingBottom: '5rem'
      }}>
      <img src={orbs} alt='wise liquid nfts' style={{ width: '100vw', objectFit: "contain" }} />
    </div>
  );
};

export default OrbsSection;
