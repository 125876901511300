import React, { useState, useEffect } from 'react';
import { animated, config, useSpring } from 'react-spring';
import { useMediaQuery } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import screenshot1 from '../assets/images/screenshot1.png';
import screenshot2 from '../assets/images/screenshot2.png';
import screenshot3 from '../assets/images/screenshot3.png';

const gradBlueGradient = 'linear-gradient(180deg, #151A3A 0%, #2724CA 100%)';
const blueishBlack = '#151A3A';
// const useStyles = makeStyles(() => ({}));

const ScreenshotSection = () => {
  //   const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.3,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && !scrolled) setScrolled(true);
  }, [inView, scrolled]);
  const [screenshotHoverOrder, setScreenshotHoverOrder] = useState([
    '1',
    '2',
    '3'
  ]);

  const handleScreenshotHovered = ({ target: { id } }) => {
    const filtered = screenshotHoverOrder.filter((hoverId) => hoverId !== id);
    filtered.push(id);
    setScreenshotHoverOrder(filtered);
  };

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const animation = useSpring({
    opacity: scrolled ? '1' : '0',
    transform: scrolled
      ? 'translateX(-50%) translateY(-420px)'
      : 'translateX(-50%) translateY(100px)',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginLeft: '50%',
    config: config.molasses
  });
  return (
    <>
      <div style={{ background: blueishBlack, height: '883px' }} />
      <div
        style={{
          marginTop: mediumScreen ? '0' : '-650px',
          paddingTop: mediumScreen ? '12rem' : '',
          background: gradBlueGradient,
          height: mediumScreen ? '700px' : largeScreen ? '850px' : '1000px'
        }}>
        <div ref={ref} style={{ transform: 'translateY(250px)' }} />
        <animated.div style={animation}>
          <div
            style={{
              width: smallScreen ? '460px' : mediumScreen ? '500px' : '600px',
              marginTop: '400px'
            }}>
            <img
              alt='contributions screenshot'
              src={screenshot1}
              id='1'
              onMouseOver={handleScreenshotHovered}
              style={{
                position: 'absolute',
                zIndex:
                  screenshotHoverOrder[2] === '1'
                    ? '10'
                    : screenshotHoverOrder[1] === '1'
                    ? '5'
                    : '0',
                marginTop: '-3rem',
                opacity: screenshotHoverOrder[2] === '1' ? '1' : '0.8',
                filter:
                  screenshotHoverOrder[2] === '1' ? 'blur(0px)' : 'blur(1px)',
                transform: screenshotHoverOrder[2] === '1' ? 'scale(1.05)' : '',
                transition: 'all 0.3s ease',
                marginLeft: mediumScreen ? '9rem' : '16rem',
                width: smallScreen ? '300px' : mediumScreen ? '360px' : '425px'
              }}
            />
            <img
              alt='nft listings screenshot'
              src={screenshot2}
              id='2'
              onMouseOver={handleScreenshotHovered}
              style={{
                position: 'absolute',
                zIndex:
                  screenshotHoverOrder[2] === '2'
                    ? '10'
                    : screenshotHoverOrder[1] === '2'
                    ? '5'
                    : '0',
                marginLeft: mediumScreen ? '1rem' : '-5rem',
                width: smallScreen ? '300px' : mediumScreen ? '360px' : '425px',
                opacity: screenshotHoverOrder[2] === '2' ? '1' : '0.8',
                filter: screenshotHoverOrder[2] === '2' ? '' : 'blur(1px)',
                transform: screenshotHoverOrder[2] === '2' ? 'scale(1.05)' : '',
                transition: 'all 0.6s ease'
              }}
            />
            <img
              alt='statistics screenshot'
              src={screenshot3}
              id='3'
              onMouseOver={handleScreenshotHovered}
              style={{
                zIndex:
                  screenshotHoverOrder[2] === '3'
                    ? '10'
                    : screenshotHoverOrder[1] === '3'
                    ? '5'
                    : '0',
                marginLeft: '5rem',
                marginTop: smallScreen
                  ? '-8rem'
                  : mediumScreen
                  ? '-12rem'
                  : '-14rem',
                position: 'absolute',
                opacity: screenshotHoverOrder[2] === '3' ? '1' : '0.8',
                filter: screenshotHoverOrder[2] === '3' ? '' : 'blur(1px)',
                transform: screenshotHoverOrder[2] === '3' ? 'scale(1.05)' : '',
                transition: 'all 0.3s ease',
                width: smallScreen ? '300px' : mediumScreen ? '360px' : '425px'
              }}
            />
          </div>
        </animated.div>
      </div>
    </>
  );
};

export default ScreenshotSection;
