import React, { useState, useEffect } from 'react';
import { useTransition, animated, config } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const blueishBlack = '#151A3A';
const radioactivePurplesBackground =
  'linear-gradient(180deg, #151A3A 0%, #131B68 24.44%, #922c89 100%)';

const useStyles = makeStyles(() => ({
  wiseLink: {
    textDecoration: 'none',
    color: 'white !important',
    '&:hover': {
      color: `${blueishBlack} !important`
    }
  }
}));

const IntroductionSection = ({ overViewRef }) => {
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const { ref, inView } = useInView({
    threshold: 0.8,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView && !scrolled) setScrolled(true);
  }, [inView, scrolled]);

  const sections = [
    <>
      <Typography
        style={{
          fontSize: '25px',
          fontWeight: 'bold',
          margin: '1rem 0',
          maxWidth: '600px'
        }}>
        Introducing
      </Typography>
      <Typography
        style={{
          fontSize: '40px',
          fontWeight: 'bold',
          margin: '1rem 0',
          maxWidth: '600px'
        }}>
        LiquidNFTs
      </Typography>
    </>,
    <Typography
      style={{
        color: 'white',
        fontSize: '16px',
        margin: '1rem 0',
        maxWidth: '620px'
      }}>
      LiquidNFTs is a fully decentralized platform that unlocks the liquidity
      potential of NFTs.
    </Typography>,
    <Typography>
      Borrow against the value of your NFT or fund proposals on the platform in
      order to earn.
    </Typography>,
    <Typography
      style={{
        color: 'white',
        fontSize: '16px',
        margin: '1rem 0',
        maxWidth: '600px'
      }}>
      LiquidNFTs is an extension of the{' '}
      <a
        className={classes.wiseLink}
        href='https://wisetoken.net/'
        target='_blank'
        rel='noopener noreferrer'>
        Wise Token not-for-profit ecosystem
      </a>
      .
    </Typography>,
    <Typography style={{ maxWidth: '600px' }}>
      Join us on this journey to unite the DeFi and NFT spaces.
    </Typography>
  ];
  const animation = useTransition(sections, {
    from: { opacity: '0', transform: 'translateY(-20px)' },
    enter: { opacity: '1', transform: 'translateY(0px)' },
    trail: 300,
    config: config.molasses
  });

  return (
    <div
      ref={ref}
      style={{
        color: 'white',
        background: radioactivePurplesBackground,
        minHeight: '499px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        padding: '0 3rem'
      }}>
      <div ref={overViewRef} style={{ transform: 'translateY(-150px)' }} />
      {scrolled &&
        animation((props, item) => (
          <animated.div style={props}>{item}</animated.div>
        ))}
    </div>
  );
};

export default IntroductionSection;
