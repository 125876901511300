import React, { useState } from 'react';
import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  useMediaQuery,
  Button
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { makeStyles } from '@mui/styles';
import teamImages from '../assets/images/team';
import miasma from '../assets/images/miasma.svg';
import telegram from '../assets/images/icons/telegram.svg';
import discord from '../assets/images/icons/discord.svg';
import twitter from '../assets/images/icons/twitter.svg';
const blueishBlack = '#151A3A';
const lightPurple = '#6A6A9F';

const useStyles = makeStyles(() => ({
  linkedInLink: {
    textDecoration: 'none',
    color: 'white'
  },
  notifyMe: {
    width: '134px',
    textTransform: 'none !important',
    background: `${lightPurple} !important`,
    color: 'white !important',
    fontWeight: '700 !important',
    fontSize: '16px',
    height: '2.5rem',
    borderRadius: '5px',
    transition: 'opacity 0.3s ease !important',
    '&:hover': {
      opacity: '0.8'
    }
  },
  socialLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
      opacity: '0.5'
    }
  }
}));

const TeamSection = ({ teamRef }) => {
  const classes = useStyles();
  const mediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  return (
    <>
    <div style={{ minHeight: '4rem', background: blueishBlack }} />
      <div
        id='team'
        ref={teamRef}
        style={{
          background: blueishBlack,
          paddingLeft: smallScreen ? '0' : '1rem',
          paddingRight: smallScreen ? '0' : '1rem',
          paddingBottom: '3rem'
        }}>
        <Typography
          style={{
            fontWeight: 'bold',
            paddingTop: '4rem',
            marginBottom: '4rem',
            fontSize: '30px',
            lineHeight: '16.5px',
            color: 'white',
            textAlign: 'center'
          }}>
          MEET THE TEAM
        </Typography>
        <TableContainer
          style={{ marginLeft: '50%', transform: 'translateX(-50%)' }}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                padding: '2rem 0'
              }
            }}>
            <TableBody>
              <TableRow>
                {team.row1.map((member) => (
                  <TeamMember
                    props={{ member, smallScreen, mediumScreen }}
                    key={member.name}
                  />
                ))}
              </TableRow>
              <TableRow>
                {team.row2.map((member) => (
                  <TeamMember
                    props={{ member, smallScreen, mediumScreen }}
                    key={member.name}
                  />
                ))}
              </TableRow>
              <TableRow>
                {team.row3.map((member) => (
                  <TeamMember
                    props={{ member, smallScreen, mediumScreen }}
                    key={member.name}
                  />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div
        style={{
          minHeight: '100vh',
          backgroundImage: `url(${miasma})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }}>
        <Typography
          style={{
            padding: '0 2rem',
            paddingTop: '9rem',
            fontWeight: 'bold',
            fontSize: '30px',
            textAlign: 'center',
            color: 'white'
          }}>
          Subscribe To Get Notified
        </Typography>
        <Typography
          style={{
            marginTop: '1rem',
            marginBottom: '5rem',
            fontSize: '18px',
            textAlign: 'center',
            color: 'white',
            padding: '0 1rem'
          }}>
          We will let you know as soon as LiquidNFTs is up and running!
        </Typography>
        <div
          style={{
            width: '50%',
            minWidth: '320px',
            marginLeft: '50%',
            transform: 'translate(-50%)'
          }}>
          {/* <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Button
                    style={{
                      width: '134px',
                      textTransform: 'none',
                      background: lightPurple,
                      color: 'white',
                      fontWeight: 'bold',
                      fontSize: '16px'
                    }}>
                    Notify me
                  </Button>
                </InputAdornment>
              )
            }}
            fullWidth
            placeholder='Enter your email'
            style={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: '8px',
              marginLeft: '50%',
              transform: 'translateX(-50%)',
              border: '1px solid #A0AEC0',
              background: 'white',
              overflow: 'hidden'
            }}
          /> */}

          <div
            id='mc_embed_signup'
            style={{ border: '2px solid #A0AEC0', borderRadius: '8px' }}>
            <form
              action='https://liquidnfts.us5.list-manage.com/subscribe/post?u=61e26c9aac8f35828dccb5970&amp;id=5cf9b29989'
              method='post'
              id='mc-embedded-subscribe-form'
              name='mc-embedded-subscribe-form'
              className='validate'
              target='_blank'
              style={{ padding: '7px' }}
              noValidate>
              <div
                id='mc_embed_signup_scroll'
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center'
                }}>
                <input
                  type='email'
                  name='EMAIL'
                  className='email'
                  id='mce-EMAIL'
                  placeholder='Enter your email'
                  required
                  style={{ border: 'none' }}
                />
                {/* Bot trap input, do not remove: */}
                <div
                  style={{ position: 'absolute', left: '-5000px' }}
                  aria-hidden='true'>
                  <input
                    type='text'
                    name='b_61e26c9aac8f35828dccb5970_5cf9b29989'
                    tabIndex='-1'
                  />
                </div>
                <div className='clear'>
                  <Button
                    type='submit'
                    name='subscribe'
                    id='mc-embedded-subscribe'
                    className={classes.notifyMe}>
                    Notify me
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Typography
          style={{
            marginTop: '8rem',
            marginBottom: '3rem',
            textAlign: 'center',
            fontWeight: 'bold',
            lineHeight: '15px',
            fontSize: '24px',
            color: 'white'
          }}>
          Stay Connected
        </Typography>

        <div
          style={{
            display: 'inline-flex',
            justifyContent: 'space-evenly',
            width: '50%',
            minWidth: '250px',
            marginLeft: '50%',
            marginBottom: '1rem',
            transform: 'translateX(-50%)'
          }}>
          <a
            href='https://t.me/WiseToken'
            target='_blank'
            rel='noopener noreferrer'
            className={classes.socialLink}>
            <img
              src={telegram}
              alt='telegram'
              style={{ paddingBottom: '.2rem', marginRight: '.5rem' }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: 'white',
                fontWeight: 'bold',
                marginRight: '1rem'
              }}>
              TELEGRAM
            </Typography>
          </a>
          <a
            href='https://discord.gg/keJUb4W'
            target='_blank'
            rel='noopener noreferrer'
            className={classes.socialLink}>
            <img
              src={discord}
              alt='discord'
              style={{ paddingBottom: '.3rem', marginRight: '.5rem' }}
            />
            <Typography
              style={{
                fontSize: '14px',
                color: 'white',
                fontWeight: 'bold',
                marginRight: '1rem'
              }}>
              DISCORD
            </Typography>
          </a>
          <a
            href='https://twitter.com/wise_token'
            target='_blank'
            rel='noopener noreferrer'
            className={classes.socialLink}>
            <img
              src={twitter}
              alt='twitter'
              style={{ paddingBottom: '.2rem', marginRight: '.5rem' }}
            />
            <Typography
              style={{ fontSize: '14px', color: 'white', fontWeight: 'bold' }}>
              TWITTER
            </Typography>
          </a>
        </div>
      </div>
    </>
  );
};

const TeamMember = ({ props: { member, smallScreen, mediumScreen } }) => {
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  return (
    <TableCell
      align='center'
      padding={smallScreen ? 'none' : 'normal'}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}>
      <a
        className={classes.linkedInLink}
        href={member.linkedIn}
        target='_blank'
        rel='noopener noreferrer'
   >
            <div style={{
                position: 'relative',
                transform: hover ? 'scale(1.1)' : 'scale(1)',
                transition: 'all 0.3s ease'
            }}>
        <div
          style={{
            left: '50%',
            borderRadius: '100%',
            transform: 'translateX(-50%)',
            transition: 'all 0.5s ease',
            background: hover ? 'rgba(0,0,0,0.36)' : 'rgba(255, 0, 164, 0.26)',
            position: 'absolute',
            width: smallScreen ? '61px' : mediumScreen ? '111px' : '186px',
            height: smallScreen ? '60px' : mediumScreen ? '110px' : '185px',
            zIndex: '1'
          }}>
          {!mediumScreen && (
            <div
              style={{
                position: 'absolute !important',
                transition: 'all 0.5s ease',
                transform: 'translateY(120px)',
                opacity: hover ? '1' : '0',
                color: 'white !important',
                zIndex: '3',
                fontWeight: '700',
                textAlign: 'center',
                fontSize: '12px',
                lineHeight: '15px'
              }}>
              <p>{member.name}</p>
              <p>{member.title}</p>
            </div>
          )}
        </div>
        <img
          src={member.src}
          alt={member.name}
          style={{
            display: 'inline',
            borderRadius: '100%',
            width: smallScreen ? '60px' : mediumScreen ? '110px' : '185px',
            height: smallScreen ? '60px' : mediumScreen ? '110px' : '185px',
            transition: 'all 0.4s ease'
          }}
        />
        </div>
      </a>
    </TableCell>
  );
};

const team = {
  row1: [
    {
      name: 'Peter Girr',
      title: 'Chief Executive Officer',
      src: teamImages.peter,
      linkedIn: 'https://www.linkedin.com/in/peter-girr-7415ab67/'
    },
    {
      name: 'Aaron Hanson',
      title: 'Chief Technology Officer',
      src: teamImages.aaron,
      linkedIn: 'https://www.linkedin.com/in/ildiscgolfer/'
    },
    {
      name: 'Vitally Marinchenko',
      title: 'Lead Software Engineer',
      src: teamImages.vitally,
      linkedIn: 'https://www.linkedin.com/in/vitallymarinchenko'
    },
    {
      name: 'Christoph Krpoun',
      title: 'Mathematician',
      src: teamImages.christoph,
      linkedIn: 'https://www.linkedin.com/in/christoph-krpoun-2762a4175'
    }
  ],
  row2: [
    {
      name: 'Devin Marty',
      title: 'Director of Strategy',
      src: teamImages.devin,
      linkedIn: 'https://www.linkedin.com/in/devinmarty'
    },
    {
      name: 'Rene Von Mangoldt',
      title: 'Mathematician',
      src: teamImages.rene
    },
    {
      name: 'Garrick Hyde-White',
      title: 'NFT Consultant',
      src: teamImages.garrick,
      linkedIn: 'https://www.linkedin.com/in/garrickhw'
    },
    {
      name: 'Alice Chen',
      title: 'Marketing and Advertising',
      src: teamImages.alice,
      linkedIn: 'https://www.linkedin.com/in/tingyuan-alice-chen-294155118'
    }
  ],
  row3: [
    {
      name: 'James Guo',
      title: 'Software Engineer',
      src: teamImages.james,
      linkedIn: 'https://www.linkedin.com/in/sunmandev/'
    },
    {
      name: 'Thomas Foydel',
      title: 'Software Engineer',
      src: teamImages.thomas,
      linkedIn: 'https://www.linkedin.com/in/thomasfoydel/'
    },
    {
      name: 'Sapir Matmon',
      title: 'UI/UX Designer',
      src: teamImages.sapir,
      linkedIn: 'https://www.linkedin.com/in/sapir-m/'
    },
    {
      name: 'Bruce Darcy',
      title: 'Software Engineer',
      src: teamImages.bruce,
      linkedIn: 'https://www.linkedin.com/in/bruce-darcy-82a55a162/'
    }
  ]
};

export default TeamSection;
